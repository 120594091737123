
document.addEventListener('DOMContentLoaded', () => {
    MicroModal.init({
        disableFocus: true
    });

    // initialize default meeting date
    const datepickerElement = document.querySelector('#datepicker')
    let currentDate = new Date().toISOString()
    let defaultDateValue = currentDate.slice(0,11)
    let defaultDateTime = eval(parseInt(currentDate.slice(11,13)) + 2)
    datepickerElement.value = defaultDateValue + defaultDateTime + ':00';
    // initialize placeholder
    var date = new Date(datepickerElement.value);
    var options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    var dateLisible = date.toLocaleDateString('fr-FR', options);
    document.getElementById('invisible-date').innerText = dateLisible


    // placeholder date
    datepickerElement.addEventListener('change', (evt) => {
        let inputDate = evt.target.value;
        var date = new Date(inputDate);
        var options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        var dateLisible = date.toLocaleDateString('fr-FR', options);
        evt.target.classList.add('field-filled')
        document.getElementById('invisible-date').innerText = dateLisible
    })

    // placeholder ville
    const labels = document.querySelectorAll('input[name="meeting-location"] + label')
    labels.forEach((label, index) => {
        label.addEventListener('click', function(event) {
            document.querySelectorAll('input[name="meeting-location"] + label').forEach((element) => { element.classList.add('field-filled'); })
            event.preventDefault()
            let nextIndex = (index + 1) % labels.length
            let nextRadioId = labels[nextIndex].getAttribute('for')
            document.getElementById(nextRadioId).checked = true
        });
    });



    document.getElementById('send-mail-to-plan-net').addEventListener('click', (evt) => {
        let href = evt.target.dataset.href + '?body=' + document.getElementById('mail-template').innerText
        href = href.replace(/(\r\n|\n|\r+)/gm, '%0D%0A').trim()
        evt.target.classList.add('sended')
        window.location.href = href
    })

    wishAndHappyNewYearToDevelopers()

    onKonamiCode(function () {
        snowf.init({
                size: 5,
                amount: 150,
                dom: document.body
            });
        }
    )

})





function wishAndHappyNewYearToDevelopers() {
    console.log('%c░       ░░░      ░░   ░░░  ░   ░░░  ░        ░░░░░      ░░   ░░░  ░   ░░░  ░        ░        ░\n' +
        '▒  ▒▒▒▒  ▒  ▒▒▒▒  ▒    ▒▒  ▒    ▒▒  ▒  ▒▒▒▒▒▒▒▒▒▒  ▒▒▒▒  ▒    ▒▒  ▒    ▒▒  ▒  ▒▒▒▒▒▒▒  ▒▒▒▒▒▒▒\n' +
        '▓       ▓▓  ▓▓▓▓  ▓  ▓  ▓  ▓  ▓  ▓  ▓      ▓▓▓▓▓▓  ▓▓▓▓  ▓  ▓  ▓  ▓  ▓  ▓  ▓      ▓▓▓      ▓▓▓\n' +
        '█  ████  █  ████  █  ██    █  ██    █  ██████████        █  ██    █  ██    █  ███████  ███████\n' +
        '█       ███      ██  ███   █  ███   █        ████  ████  █  ███   █  ███   █        █        █\n' +
        '                                                                                           ',
         "color:green; font-size: 10px;")
    console.log('༼ つ ◕_◕ ༽つ🍪')

}

function onKonamiCode(cb) {
    var input = '';
    var key = '38384040373937396665';
    console.log('%c Konami Code & let it snow!', 'font-weight: bold; font-size: 18px;color: red; text-shadow: 1px 1px 0 rgb(255,255,255), 2px 2px 0 rgb(0,0,0)');
    document.addEventListener('keydown', function (e) {
        input += ("" + e.keyCode);
        if (input === key) {
            return cb();
        }
        if (!key.indexOf(input)) return;
        input = ("" + e.keyCode);
    });
}
